<template>
    <form
        class="space-y-6"
        @submit.prevent="submit"
    >
        <h3 class="subtitle">
            Ajouter une
            {{
                formData.type == 5 || formData.type == 6 || formData.type == 7 || formData.type == 8 ? 'absence' : 'présence'
            }}
            pour {{ baby.first_name }} {{ baby.last_name }}
        </h3>
        <div class="grid-cols-2 grid gap-6">
            <UiInputDate
                v-model="day"
                name="day"
                label="Date"
                :errors="errors"
                required
            />
            <div
                v-show="!showTo"
                class="flex items-end justify-end"
            >
                <button
                    class="btn btn-link"
                    type="button"
                    @click="showTo = true"
                >
                    Encoder pour une période
                </button>
            </div>
            <UiInputDate
                v-if="showTo"
                v-model="formData.to"
                label="Encoder la même chose jusqu'au"
                :min="day"
                name="to"
                :errors="errors"
                required
            />
        </div>
        <div>
            <UiSelect
                v-model="formData.type"
                name="type"
                label="Type"
                required
                :errors="errors"
                :options="realPresences"
            />
            <div
                v-if="allowCreateReservation"
            >
                <UiButtonModal id="reservation-create-form">
                    <template #trigger>
                        <button
                            type="button"
                            class="btn btn-sm btn-link"
                        >
                            Encoder une réservation
                        </button>
                    </template>
                    <ReservationCreateForm
                        :baby="baby"
                        :registration="registration"
                        :date="date"
                    />
                </UiButtonModal>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-6">
            <UiInput
                v-model="start_at"
                :label="[1, 2, 10].includes(formData.type) ? 'Arrivé à' : 'De'"
                type="time"
                name="start_at"
                required
                :errors="errors"
            />
            <UiInput
                v-model="left_at"
                :label="[1, 2, 10].includes(formData.type) ? 'Parti à' : 'à'"
                type="time"
                name="left_at"
                required
                :errors="errors"
            />
        </div>
        <UiCheckbox
            v-if="formData.type === 10"
            v-model="formData.is_familiarization_with_parent"
            name="is_familiarization_with_parent"
        >
            Familiarisation avec accompagnement parent(s)
        </UiCheckbox>
        <UiTextarea
            v-model="formData.note"
            label="Remarques"
            name="note"
            :errors="errors"
        />

        <div
            v-if="shouldShowAttendanceDocument"
            class="pt-6 space-y-6 border-t"
        >
            <UiInputFile
                v-model="document"
                label="Justificatif"
                name="document"
                :errors="errors"
                placeholder="Document justificatif"
            />
            <UiSelect
                v-model="documentType"
                name="documentType"
                :errors="errors"
                placeholder="Type de justificatif"
                :options="documentTypes"
            />
        </div>

        <div
            v-if="shouldShowQuota"
            class="pt-6 border-t"
        >
            <label
                for="quota"
                class="mb-1 label"
            >Quotas</label>
            <table
                aria-label="Quotas"
                class="table-default small"
            >
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Utilisés</th>
                        <th>Disponible</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Congés annuels</td>
                        <td>{{ quota.yearly.used }}</td>
                        <td>
                            <span :class="{ 'text-red-500': quota.yearly.remaining <= 0 }">
                                {{ quota.yearly.remaining }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Absences justifiées trimestrielles<br>(déclaration sur l'honneur max 1 jour)</td>
                        <td>{{ quota.quaterlySworn.used }}</td>
                        <td>
                            <span :class="{ 'text-red-500': quota.quaterlySworn.remaining <= 0 }">
                                {{ quota.quaterlySworn.remaining }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Absences justifiées trimestrielles<br>(tout type)</td>
                        <td>{{ quota.quaterly.used }}</td>
                        <td>
                            <span :class="{ 'text-red-500': quota.quaterly.remaining <= 0 }">
                                {{ quota.quaterly.remaining }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div
                class="text-xs mt-1 text-gray-500"
            >
                Si un quota disponible est à 0, vous pouvez, si vous le souhaitez, rendre cette absence <strong>injustifiée</strong>
                (facturable)
            </div>
        </div>

        <div class="text-right">
            <button
                type="submit"
                class="btn btn-primary"
                :class="{ loading: isLoading }"
            >
                Soumettre
            </button>
        </div>
    </form>
</template>

<script>
import dayjs from 'dayjs'
import {
    absenceTypes,
    FULL_DAY_JUSTIFIED_ABSENCE,
    HALF_DAY_JUSTIFIED_ABSENCE,
    realPresences
} from 'abcreche-constants/src/const/attendances/types'
import { loadingMixin } from 'abcreche-ui'
import documentTypes from 'abcreche-constants/src/const/attendanceDocuments/types'
import AttendanceRepository from '~/repositories/Creche/AttendanceRepository.js'
import AttendanceDocumentsRepository from '~/repositories/Creche/AttendanceDocumentsRepository.js'
import RegistrationRepository from '~/repositories/Creche/RegistrationRepository.js'

export default {
    mixins: [loadingMixin],

    props: {
        date: {
            type: [String, Object, Date],
            default: () => dayjs()
        },
        baby: {
            type: Object,
            required: true
        },
        registration: {
            type: Object,
            required: true
        },
        reservation: {
            type: Object,
            default: undefined
        },
        allowCreateReservation: {
            type: Boolean,
            default: false
        }
    },

    emits: ['created'],

    data () {
        return {
            formData: {
                baby_uuid: this.baby.uuid,
                is_familiarization_with_parent: false,
                type: this.determineType(),
                note: undefined
            },
            day: undefined,
            start_at: '09:00',
            left_at: '18:00',
            justified_at: undefined,
            quota: null,
            document: undefined,
            documentType: undefined,
            showTo: false
        }
    },

    computed: {
        realPresences,
        documentTypes: documentTypes.all,
        defaultLeftAt () {
            const day = dayjs(this.date).locale('en').format('dddd').toLowerCase()
            if (this.$creche.opening_time) {
                return this.$creche.opening_time[day]?.closes_at || '18:00'
            }
            return '18:00'
        },
        defaultStarAt () {
            const day = dayjs(this.date).locale('en').format('dddd').toLowerCase()
            if (this.$creche.opening_time) {
                return this.$creche.opening_time[day]?.opens_at || '08:00'
            }
            return '08:00'
        },
        shouldShowQuota () {
            return absenceTypes
                .includes(this.formData.type) && this.quota
        },
        shouldShowAttendanceDocument () {
            return [HALF_DAY_JUSTIFIED_ABSENCE, FULL_DAY_JUSTIFIED_ABSENCE]
                .includes(this.formData.type)
        }
    },

    created () {
        this.day = dayjs(this.date).format('YYYY-MM-DD')
        this.start_at = this.defaultStarAt
        this.left_at = this.defaultLeftAt

        this.getQuota()
        if (this.reservation) {
            this.start_at = this.reservation.start_at ? dayjs(this.reservation.start_at).format('HH:mm') : this.defaultStarAt
            this.left_at = this.reservation.left_at ? dayjs(this.reservation.left_at).format('HH:mm') : this.defaultLeftAt
        }
    },

    methods: {
        submit () {
            this.load()

            const datetime = this.day
            const leftAt = this.day + ' ' + this.left_at + ':00'
            const startAt = this.day + ' ' + this.start_at + ':00'

            this.formData.datetime = datetime
            this.formData.start_at = startAt
            this.formData.left_at = leftAt
            this.formData.justified_at = dayjs().format('YYYY-MM-DD HH:mm')

            const api = this.showTo ? AttendanceRepository.createByPeriod : AttendanceRepository.create

            api(this.registration.uuid, this.formData)
                .then((response) => {
                    if (this.document || this.documentType) {
                        this.submitDocument(response.data)
                    } else {
                        this.$emit('created', response.data)
                        this.done('La présence a été correctement enregistrée !')
                    }
                })
                .catch(errors => this.doneWithErrors(errors))
        },

        submitDocument (attendance) {
            const formData = new FormData()
            if (this.showTo) {
                attendance.forEach((att) => {
                    formData.append('attendance_uuids[]', att.uuid)
                })
            } else {
                formData.append('attendance_uuid', attendance.uuid)
            }
            formData.append('document_path', this.document)
            formData.append('type', this.documentType)

            AttendanceDocumentsRepository.create('registrations', this.registration.uuid, formData)
                .then(() => {
                    this.done('La présence a été correctement enregistrée !')
                    this.$emit('created')
                })
                .catch((error) => {
                    this.doneWithErrors(error.response.data)
                })
        },

        getQuota () {
            if (this.registration) {
                RegistrationRepository.quota(this.registration.uuid, {
                    date: this.day
                })
                    .then((response) => {
                        this.quota = response
                    })
                    .catch(error => this.pageError(error))
            }
        },

        determineType () {
            if (this.reservation && this.reservation.type === 4) {
                return 2
            }
            return 1
        }
    }
}
</script>
